import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import paragraphs from "lines-to-paragraphs"

const IndexPage = ({ data }) => {
  const { name : fullName, about: { about }, cv, image } = data.contentfulPerson;
  return (
    <Layout fullName={ fullName } cv={ cv.file.url } profile= { image.file.url }>
      <SEO title="About" />
      <div dangerouslySetInnerHTML={{ __html: paragraphs(about) }} />
    </Layout>
  );
};

export default IndexPage

export const pageQuery = graphql`
{
  contentfulPerson(name: { eq: "Elysia Vaccarino" }) {
    id
    name
    about {
      about
    }
    cv {
      file {
        url
      }
    }
    image {
      file {
        url
      }  
    }
  }
}
`;